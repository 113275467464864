import type { NextPage } from "next";
import Router from "next/router";

import LanguageSelection from "~/modules/languageSelection";

import routes from "~/routes/main";

type LoginType = {
  login: boolean;
};
const Home: NextPage<any> = ({ login }: LoginType) => {
  if (!login) {
    Router.push(routes.tripManifest);
    return null;
  }
  return <LanguageSelection />;
};
// eslint-disable-next-line func-style
export async function getServerSideProps(cxt: any) {
  const serverRefreshToken = cxt.req
    ? cxt.req?.headers?.cookie?.split("saarDriverRefreshToken=")[1]
    : "";
  if (!serverRefreshToken) {
    return { props: { login: true } };
  }
  return { props: { login: false } };
}
export default Home;
