import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";

import { useAppDispatch } from "~/state/hooks";

import { Language } from "~/gql/main/types.generated";

import Container from "~/core/components/InitialScreenContainer";
import { setLanguage } from "~/core/state/settingsSlice";
import routes from "~/routes/main";

const LanguageSelection = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const chooseLanguageEn = "Choose Language";
  const chooseLanguageAr = "اختر اللغة";

  const english = "English";
  const arabic = "عربي";

  const handleClick = (language: Language) => {
    dispatch(setLanguage(language));
    router.push(routes.login);
  };

  return (
    <Container>
      <Typography
        variant="h2"
        color="primary"
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: 1,
        }}
      >
        {chooseLanguageEn}
      </Typography>
      <Typography
        variant="h2"
        color="primary"
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: 5,
        }}
      >
        {chooseLanguageAr}
      </Typography>

      <Button
        variant="outlined"
        size="large"
        sx={{
          width: "90%",
          paddingY: 2,
          fontWeight: "bold",
          marginBottom: 3,
        }}
        onClick={() => handleClick(Language.EN)}
      >
        {english}
      </Button>

      <Button
        variant="outlined"
        size="large"
        sx={{
          width: "90%",
          paddingY: 2,
          fontWeight: "bold",
        }}
        onClick={() => handleClick(Language.AR)}
      >
        {arabic}
      </Button>
    </Container>
  );
};

export default LanguageSelection;
