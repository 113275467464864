import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const styles = (theme: any) => ({
  backgroundImage: `url("assets/loginIllustrations.png")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "65% 25%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ".MuiBox-root": {
    textAlign: "center",
    width: "90%",
    paddingY: 3,
    paddingX: 1,
    [theme.breakpoints.up("sm")]: {
      paddingY: 7,
      paddingX: 3,
      border: "1px solid #eeeeee",
      borderRadius: "3%",
      boxShadow: "2px 2px 0px 0px #d7d7d7",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "60%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "32%",
    },
  },
});

const Container = ({ children }: { children: ReactNode | ReactNode[] }) => {
  return (
    <Paper sx={styles}>
      <Box>{children}</Box>
    </Paper>
  );
};

export default Container;
